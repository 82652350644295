import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import TokenDetails from "./TokenDetails/TokenDetails"
import Marketplace from "./Marketplace/Marketplace"
import CCXToken from "../abis/CCXNFT_V4.json"
import { Token, Offer, Bid } from "./Models"
import MintToken from "./Mint/MintToken"
import React, { Component } from "react"
import Footer from "./Navbar/Footer"
import Header from "./Navbar/Header"
import Wallet from "./Wallet/Wallet"
import Traits from "./Traits/Traits"
import Trait from "./Traits/Trait"
import ReactGA from "react-ga"
import Home from "./Home/Home"
import Web3 from "web3"
import "./www.css"

export const TruncatedAddress = (address) => {
  let amount = 6
  let length = address.length
  return address.substr(0, amount) + " … " + address.slice(length - amount)
}

export const GetTokenURI = (tokenURI) => {
  return tokenURI.replace('ipfs://', 'https://ipfs.io/ipfs/')
}

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      accountAddress: undefined,
      contract: undefined,
      wallet: [],
      tokens: [],
      offers: [],
      bids: [],
      userMintAmount: 0,
      mintingCost: 0,
      royaltyFee: 0,
      loading: true,
      usdPrice: 0,
      isMetamaskConnected: false
    }
  }

  setupAnalytics = () => {
    ReactGA.initialize("G-0JSS01NF98")
    ReactGA.pageview("Init start page")
  }

  componentDidMount = async () => {
    this.setupAnalytics()
    await this.loadWeb3()
    await this.loadBlockchainData()
  }

  loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
  }

  getAccountDetails = async (accounts) => {
    let account = accounts[0].toLowerCase()
    this.setState({ accountAddress: account })
    this.setState({ isMetamaskConnected: true })
  }

  getMetadata = async (contract) => {
    let tokenId = 1
    let firstToken = await contract.methods.tokenURI(tokenId).call()
    let uri = GetTokenURI(firstToken)
    let newURI = uri.replace(`${tokenId}.json`, "_metadata.json")
    let result = await fetch(newURI)
    let metadata = await result.json()
    return metadata
  }

  getCurrentUSDPrice = async _ => {
    let result = await fetch("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD")
    let json = await result.json()
    let usd = json.USD
    return usd
  }

  calculateUSDPrice = (wei) => {
    let price = window.web3.utils.fromWei(wei.toString(), "ether")
    let round = 10
    let converted = Math.round(this.state.usdPrice * price * round) / round
    return converted.toLocaleString()
  }

  loadBlockchainData = async () => {
    this.setState({ loading: true })

    const accounts = await window.web3.eth.getAccounts()

    if (accounts.length > 0) {
      this.getAccountDetails(accounts)
    } else {
      this.setState({ isMetamaskConnected: false })
    }

    const networkId = await window.ethereum.request({ method: "net_version" })

    if (networkId === "4") {
      const contract = window.web3.eth.Contract(CCXToken.abi, "0x751c291D8D612Bbb09089457D0397a30BC764374")
      this.setState({ contract })

      const royalty = await contract.methods.royaltyRate().call()
      this.setState({ royaltyFee: parseInt(royalty) })

      const userMintAmount = await contract.methods.userMintAmount().call()
      this.setState({ userMintAmount: parseInt(userMintAmount) })

      const mintingCost = await contract.methods.cost().call()
      this.setState({ mintingCost: parseInt(mintingCost) })

      const allTokens = await contract.methods.allTokens().call()
      const metadata = await this.getMetadata(contract)

      let tokens = allTokens.map((data) => {
        let token = new Token(data)
        token.metadata = metadata.find(data => data.name === `${token.tokenId}`)
        return token
      })

      if (this.state.accountAddress !== undefined) {
        const myTokens = await this.state.contract.methods.walletOfOwner(this.state.accountAddress).call()
        const wallet = tokens.filter(function (token) {
          return myTokens.map(token => token.toNumber()).indexOf(token.tokenId.toNumber()) !== -1
        })
        this.setState({ wallet })
      }

      const allBids = await contract.methods.activeBids().call()
      let bids = allBids.map((bid) => { return new Bid(bid) })

      const allOffers = await contract.methods.activeOffers().call()
      let offers = allOffers.map((offer) => { return new Offer(offer) })

      let usdPrice = await this.getCurrentUSDPrice()

      this.setState({ tokens, offers, bids, usdPrice })
      this.setState({ loading: false })

      window.localStorage.setItem("state", this.state)

      window.ethereum.on("accountsChanged", async (accounts) => {
        if (accounts.length > 0) {
          this.getAccountDetails(accounts)
        } else {
          this.setState({ isMetamaskConnected: false })
        }
      })
    }
  }

  render() {
    return (
      <div className="container p-5 d-flex flex-column min-vh-100">
        <Router>
          <Header accountAddress={this.state.accountAddress} metamaskConnected={this.state.isMetamaskConnected} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/mint" element={<MintToken contract={this.state.contract} accountAddress={this.state.accountAddress} userMintAmount={this.state.userMintAmount} royaltyFee={this.state.royaltyFee} />} mintingCost={this.state.mintingCost} />
            <Route path="/clocks" element={<Marketplace usdPrice={this.state.usdPrice} tokens={this.state.tokens} offers={this.state.offers} bids={this.state.bids} />} />
            <Route path="/clocks/:id" element={<TokenDetails usdPrice={this.state.usdPrice} tokens={this.state.tokens} offers={this.state.offers} bids={this.state.bids} accountAddress={this.state.accountAddress} contract={this.state.contract} />} />
            <Route path="/address/:address" element={<Wallet props={{ usdPrice: this.state.usdPrice, offers: this.state.offers, address: this.state.accountAddress, bids: this.state.bids, tokens: this.state.tokens, contract: this.state.contract }} />} />
            <Route path="/traits/" element={<Traits tokens={this.state.tokens} />} />
            <Route path="/traits/:trait" element={<Trait tokens={this.state.tokens} />} />
          </Routes>
          <Footer />
        </Router>
      </div>
    )
  }
}

export default App
