import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import TokenDetail from "../Marketplace/TokenDetail"

const Traits = ({ tokens }) => {
    const [traits, setTraits] = useState([])
    const [allTraits, setAllTraits] = useState([])

    useEffect(() => {
        if (tokens[0] !== undefined) {
            let traits = tokens[0].metadata.attributes
            setTraits(traits)

            let allTraits = tokens.flatMap(token => token.metadata.attributes)
            setAllTraits(allTraits)
        }
    }, [tokens])

    return (
        <>
            <h1>Clock Traits</h1>
            {traits.map((trait) => {
                let filter = allTraits.filter(ta => ta.trait_type === trait.trait_type)
                let values = filter.filter((v, i, a) => a.findIndex(t => t.value === v.value) === i)
                return (
                    <div key={trait.trait_type} className="mt-5">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <div className="row">
                                    <div className="col-3"><b><h4>{trait.trait_type}</h4></b></div>
                                    <div className="col-2"><b><h5>#</h5></b></div>
                                    <div className="col-7"><b><h5>Clocks</h5></b></div>
                                </div>
                            </li>
                            {values.map(value => {
                                let ts = tokens.filter((token) => {
                                    let traits = token.metadata.attributes.find(attr => attr.trait_type === trait.trait_type)
                                    return traits.value === value.value
                                })
                                return (
                                    <li key={value.value} className="list-group-item">
                                        <div className="row">
                                            <div className="col-3"><Link to={`/traits/${value.value}-${trait.trait_type}`} className="link-primary">{value.value}</Link></div>
                                            <div className="col-2">{ts.length}</div>
                                            <div className="col-7">
                                                <div className="d-flex flex-wrap">
                                                    {ts.slice(0, 9).map((token) => {
                                                        return (<div key={token.tokenId}><TokenDetail token={token} size={70} /></div>)
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                )
            })}
        </>
    )
}

export default Traits
