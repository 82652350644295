import { TruncatedAddress } from "../www"
import { Link } from "react-router-dom"
import React from "react"

const DetailHeader = ({ props }) => {
  const { token } = props
  const { isOwner } = props

  let tokenId = `${token.tokenId}`
  let owner = token.owner

  return (
    <div className="mb-5">
      <div className="row">
        <h1>Crypto Clock {`${tokenId}`}</h1>
      </div>
      <div className="row">
        <h5>Owned by {isOwner() ? "you" : <Link to={`/address/${owner}`}>{TruncatedAddress(`${owner}`)}</Link>}</h5>
      </div>
    </div>
  )
}

export default DetailHeader