
import TokenImage from "../TokenImage/TokenImage"
import { Link } from "react-router-dom"
import React from "react"

const TokenDetail = ({ token, wei, usdPrice, size }) => {

    const calculateUSDPrice = (wei) => {
        let price = window.web3.utils.fromWei(wei.toString(), "ether")
        var newPrice = usdPrice * price
        let kilo = 1000.0
        let mil = 1000000.0

        if (newPrice >= mil) {
            newPrice /= mil
            return `${round(newPrice)}M`
        } else if (newPrice >= kilo) {
            newPrice /= kilo
            return `${round(newPrice)}K`
        }

        return round(newPrice)
    }

    const round = (price) => {
        let decimal = 100
        return Math.round(price * decimal) / decimal
    }

    const etherFromWei = (amount) => {
        return window.web3.utils.fromWei(amount.toString(), "ether") + " Ξ"
    }

    if (size === undefined) {
        size = 140
      }

    return (
        <Link to={`/clocks/${token.tokenId}`} style={{ color: "black" }}>
            <TokenImage token={token} size={size} />
            {wei > 0 ?
                <div style={{ fontSize: "18px", textAlign: "center", marginBottom: "20px" }}>
                    <span>{etherFromWei(wei)}</span>
                    <br></br>
                    <span>${calculateUSDPrice(wei)}</span>
                </div>
                :
                null
            }
        </Link>
    )
}
export default TokenDetail