import TokenDetail from "../Marketplace/TokenDetail"
import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { TruncatedAddress } from "../www"

const Wallet = ({ props }) => {
  const [loading, setLoading] = useState(true)
  const [myBids, setMyBids] = useState([])
  const [tokensBids, setTokenBids] = useState([])
  const [ownedTokens, setOwnedTokens] = useState([])

  const { address } = useParams()
  let accountAddress = address

  const { contract } = props
  const { bids } = props
  const { tokens } = props
  const { offers } = props
  const { usdPrice } = props

  useEffect(() => {
    if (contract !== undefined) {
      let wallet = tokens.filter(token => token.owner === accountAddress)
      setOwnedTokens(wallet)

      let myBids = bids.filter((bid) => { return bid.bidder === accountAddress })
      setMyBids(myBids)

      const tokenBids = tokens.filter((token) => {
        return myBids.map(token => token.tokenId.toNumber()).indexOf(token.tokenId.toNumber()) !== -1
      })
      setTokenBids(tokenBids)
      setLoading(false)
    }
  }, [offers, tokens, bids, accountAddress])

  const myOffers = () => {
    return offers.filter(offer => offer.seller === accountAddress)
  }

  const bidsValue = () => {
    let bidValues = myBids.map(bid => parseInt(bid.value))
    return bidValues.reduce((partialSum, a) => partialSum + a, 0)
  }

  const soldForValue = () => {
    let soldFor = ownedTokens.map(token => parseInt(token.soldFor))
    return soldFor.reduce((partialSum, a) => partialSum + a, 0)
  }

  const offersValue = () => {
    let offerValues = myOffers().map(offer => parseInt(offer.price))
    let result = offerValues.reduce((partialSum, a) => partialSum + a, 0)
    return result.toLocaleString('fullwide', { useGrouping: false })
  }

  const etherFromWei = (amount) => {
    return window.web3.utils.fromWei(amount.toString(), "ether") + " Ξ"
  }

  const calculateUSDPrice = (wei) => {
    let price = window.web3.utils.fromWei(wei.toString(), "ether")
    let round = 10
    let converted = Math.round(usdPrice * price * round) / round
    return converted.toLocaleString()
  }

  return (
    <>
      {loading ?
        null
        :
        <>
          <div className="mb-5">
            <h1>Account Info</h1>
          </div>
          <div className="mb-5">
            <div className="row">
              <div className="col">Total Clocks Owned</div>
              <div className="col">Bids placed</div>
              <div className="col">Clocks for sale</div>
            </div>
            <div className="row mb-4">
              <div className="col"><b>{ownedTokens.length}</b></div>
              <div className="col"><b>{myBids.length}</b></div>
              <div className="col"><b>{myOffers().length}</b></div>
            </div>
            <div className="row">
              <div className="col">Value of owned clocks</div>
              <div className="col">Value of bids placed</div>
              <div className="col">Value of clocks for sale</div>
            </div>
            <div className="row mb-4">
              <div className="col"><b>{etherFromWei(soldForValue())} (${calculateUSDPrice(soldForValue())})</b></div>
              <div className="col"><b>{etherFromWei(bidsValue())} (${calculateUSDPrice(bidsValue())})</b></div>
              <div className="col"><b>{etherFromWei(offersValue())} (${calculateUSDPrice(offersValue())})</b></div>
            </div>
            <div className="row">
              <div className="col">EtherScan</div>
            </div>
            <div className="row">
              <div className="col"><a href={`https://rinkeby.etherscan.io/address/${accountAddress}`} className="link-primary" target="_blank" rel="noopener noreferrer">{TruncatedAddress(accountAddress)}</a></div>
            </div>
          </div>
          <div className="mt-5">
            <h3>{ownedTokens.length.toString()} owned clocks</h3>
            <hr></hr>
            <div className="d-flex flex-wrap">
              {ownedTokens.map((token) => {
                return (
                  <div key={token.tokenId.toString()}>
                    <TokenDetail token={token} wei={0} size={70} />
                  </div>
                )
              })}
            </div>
          </div>
          <div className="mt-5">
            <h3>{myBids.length.toString()} bids placed by this account</h3>
            <hr></hr>
            <div className="d-flex flex-wrap mb-2">
              {tokensBids.map((token) => {
                let bid = myBids.find((bid) => { return parseInt(bid.tokenId) === parseInt(token.tokenId) })
                return (
                  <div key={token.tokenId.toString()}>
                    <TokenDetail token={token} wei={bid.value} usdPrice={usdPrice} />
                  </div>
                )
              })}
            </div>
          </div>
          <div className="mt-1">
            <h3>{myOffers().length} clocks for sale by this account</h3>
            <hr></hr>
            <div className="d-flex flex-wrap mb-2">
              {myOffers().map((offer) => {
                let token = ownedTokens.find((token) => { return parseInt(token.tokenId) === parseInt(offer.tokenId) })
                return (
                  <>
                    {token !== undefined && (
                      <div key={token.tokenId.toString()}>
                        <TokenDetail token={token} wei={offer.price} usdPrice={usdPrice} />
                      </div>
                    )}
                  </>
                )
              })}
            </div>
          </div>
        </>
      }
    </>
  )
}

export default Wallet
