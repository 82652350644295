import React from "react"
import { GetTokenURI } from "../www"

const TokenImage = ({ token, size }) => {

  if (size === undefined) {
    size = 140
  }

  const tokenCard = {
    width: `${size}px`,
    backgroundColor: `#${token.metadata.background_color}`
  }

  return (
    <div style={tokenCard}>
      <img src={GetTokenURI(token.metadata.image)} alt={`CryptoClock ${token.tokenId.toString()}`} width={`${tokenCard.width}`} />
    </div>
  )
}

export default TokenImage
