import LinkedIn from "./Images/linkedin.svg"
import Github from "./Images/github.svg"
import Banner from "./Images/banner.png"
import { Link } from "react-router-dom"
import Globe from "./Images/globe.svg"
import VG from "./Images/VGA1x1.jpg"
import NAMI from "./Images/NAMI.png"
import AFSP from "./Images/AFSP.svg"
import MHA from "./Images/MHA.png"
import React from "react"

export const DiscordURL = "https://discord.com/channels/921006939834556477/921007005840310333"

const Home = () => {
    let marginBottom = "80px"
    return (
        <div>
            <div className="mb-5">
                <h1 className="mb-5">Crypto Clocks</h1>
                <p>
                    Crypto Clocks is an NFT collection of 10 000 randomly generated and unique pixelated art watches stored on the <a href="https://en.wikipedia.org/wiki/Ethereum" className="link-primary" target="_blank" rel="noopener noreferrer">Ethereum</a> blockchain.
                    The Crypto Clock token [CCX] is an <a href="https://eips.ethereum.org/EIPS/eip-721" className="link-primary" target="_blank" rel="noopener noreferrer">ERC-721</a> non-fungible token that can be minted by anyone and there are only 10 000 tokens in existence.
                </p>
                <p>
                    Join us in raising mental health awareness and give to non-profit organizations that works with suicide prevention and other mental health disorders.
                    By owning, buying and selling Crypto Clocks you give to people in need and earn royalties at the same time. It's a win-win, win-win.
                </p>
            </div>
            <div style={{ marginBottom }}>
                <Link to="/mint" className="btn btn-primary me-3">Get Crypto Clocks</Link>
            </div>
            <div style={{ marginBottom }}>
                <img src={Banner} alt="Crypto Clocks Banner" style={{ width: "100%" }} />
            </div>
            <div style={{ marginBottom }}>
                <h3 className="mb-3">What is a Crypto Clock?</h3>
                <p>
                    Crypto Clocks are 64x64 pixel art that has been generated by an algorithm. The watches has different kinds of traits.
                    Some have gold bracelets and some have chrome bracelets. There are different types of dials and bezels for the clocks as well.
                    Some are rarer and have different hour and minute handles and some have different index markings on the dial. All clocks have their own page
                    that shows their traits as well as their marketplace status (take a look <Link to="/clocks/1" className="link-primary">here</Link>).
                </p>
            </div>
            <div style={{ marginBottom }}>
                <h3 className="mb-3">Make a difference</h3>
                <p>
                    With the Crypto Clock NFT project we aim to raise money for charities and non-profit organizations that works with suicide prevention and mental health disorders.
                    Every time a Crypto Clock is sold a small fee is collected and a portion of all the proceeds from buying and selling Crypto Clock tokens goes to
                    non-profit organizations that is helping people who are struggling with depression and other emotional challenges. So by owning, buying and selling Crypto Clocks you
                    are giving back to people in need.
                </p>
                <div className="row mt-1 text-center">
                    <div className="col"><img src={AFSP} alt="AFSP" style={{ width: "50%" }} /></div>
                    <div className="col"><img src={NAMI} alt="NAMI" style={{ width: "50%" }} /></div>
                    <div className="col"><img src={MHA} alt="MHA" style={{ width: "50%" }} /></div>
                </div>
            </div>
            <div style={{ marginBottom }}>
                <h3 className="mb-3">Earn royalties with Crypto Clocks</h3>
                <p>
                    As a way to incentives people to mint, buy and sell Crypto Clocks here on the marketplace, we have build a smart contract that lets anyone who have minted a Crypto Clock token
                    earn royalties every time that token is sold. The royalty rate is between 8% - 10%. If you mint a Crypto Clock and sell it on the marketplace,
                    every time that token is resold in the future, you as the initial minter will receive a royalty fee for that sale. You can read more <Link to="/mint" className="link-primary">here</Link>.
                </p>
            </div>
            <div style={{ marginBottom }}>
                <h3 className="mb-3">Join the community</h3>
                <p>
                    <a href={DiscordURL} className="link-primary" target="_blank" rel="noopener noreferrer">Join our Discord server</a> and
                    connect with our community of people who are into the NFT and crypto space. There are also channels for people to be able
                    to talk freely about metal health issues. Maybe you have a suggestion for a mental health charity or non-profit organization that you want to share.
                    We would love to hear your stories about any mental health challenges that you, or someone you love, might have faced and overcome.
                </p>
            </div>
            <div style={{ marginBottom }}>
                <h3 className="mb-3">Meet the developer</h3>
                <div className="row">
                    <div className="col-4 me-4">
                        <img src={VG} alt="Developer Viktor Gidlöf" style={{ width: "100%" }} />
                    </div>
                    <div className="col">
                        <p>
                            The Crypto Clock NFT project was created and developed by Viktor Gidlöf, a software engineer,
                            creative director and mental health activist from Sweden. He has a background in tech and mobile app
                            development and has been a part of the start up scene in Stockholm for more than eight years.
                            In recent years he has been going in to the crypto space and most recently, the NFT space.
                            He started the Crypto Clock NFT project after loosing one of his best friends to suicide.
                        </p>
                        <div className="link-primary list-group list-group-flush mt-4">
                            <div className="mb-3">
                                <img src={Globe} alt="Website" style={{ width: "4%", marginRight: "8px" }} />
                                <a href="https://viktorgidlof.com" target="_blank" rel="noopener noreferrer">Website</a>
                            </div>
                            <div className="mb-3">
                                <img src={LinkedIn} alt="LinkedIn" style={{ width: "4%", marginRight: "8px" }} />
                                <a href="https://www.linkedin.com/in/viktorgidl%C3%B6f/" target="_blank" rel="noopener noreferrer">Linked In</a>
                            </div>
                            <div className="mb-3">
                                <img src={Github} alt="LinkedIn" style={{ width: "4%", marginRight: "8px" }} />
                                <a href="https://github.com/brillcp" target="_blank" rel="noopener noreferrer">Github</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ marginBottom }}>
                <h3 className="mb-3">How can I get a Crypto Clock?</h3>
                <div className="list-group list-group-flush">
                    <span className="mb-3">1. Download the Chrome browser plugin called <a href="https://metamask.io/download/" className="link-primary" target="_blank" rel="noopener noreferrer">MetaMask</a></span>
                    <span className="mb-3">2. Buy some Ether. The MetaMask plugin will allow you to buy Ether from Coinbase</span>
                    <span className="mb-3">3. Connected your wallet to cryptoclocks.io</span>
                    <span className="mb-3">4. Go to the <Link to="/mint" className="link-primary">minting page</Link> to mint your very own Crypto Clocks</span>
                    <span className="mb-3">5. Go to the <Link to="/clocks" className="link-primary">marketplace</Link> when you have connected your wallet and bid on, buy and sell Crypto Clocks</span>
                </div>
            </div>
        </div>
    )
}

export default Home