import ConnectToMetamask from "../ConnectMetamask/ConnectToMetamask"
import { DiscordURL } from "../Home/Home"
import Discord from "./discord-logo.png"
import { Link } from "react-router-dom"
import Favico from "./favico.png"
import React from "react"

const Header = ({ accountAddress, metamaskConnected }) => {
  return (
    <nav className="navbar">
      <Link to="/" className="nav-link" style={{ fontSize: "22px", paddingLeft: "0" }}>
        <img src={Favico} alt="Discord Logo" height="30px" style={{ paddingRight: "12px" }} />
        Crypto Clocks
      </Link>
      <ul className="nav">
        <li className="nav-item"><Link to="/mint" className="nav-link">Mint CCX</Link></li>
        <li className="nav-item"><Link to="/clocks" className="nav-link">Marketplace</Link></li>
        {metamaskConnected ?
          <li className="nav-item"><Link to={`/address/${accountAddress}`} className="nav-link">Wallet</Link></li>
          :
          <li className="nav-item"><span className="nav-link"><ConnectToMetamask /></span></li>
        }
        <li className="nav-item">
          <a href={DiscordURL} className="nav-link" style={{ paddingRight: "0" }} target="_blank" rel="noopener noreferrer">
            <img src={Discord} alt="Discord Logo" height="30px" />
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default Header
