export class Token {
    constructor(token) {
        this.tokenId = token.tokenId
        this.mintedBy = token.mintedBy
        this.owner = token.currentOwner.toLowerCase()
        this.soldFor = token.soldFor
        this.metadata = null
    }
}

export class Bid {
    constructor(bid) {
        this.tokenId = bid.tokenId
        this.bidder = bid.bidder.toLowerCase()
        this.value = bid.value
    }
}

export class Offer {
    constructor(offer) {
        this.tokenId = offer.tokenId
        this.seller = offer.seller.toLowerCase()
        this.price = offer.price
    }
}
