import React, { Component } from "react"

class AmountModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: 0,
      headerTitle: props.headerTitle,
      buttonTitle: props.buttonTitle,
      disabledTitle: props.disabledTitle
    }
  }

  submitValue(value) {
    let wei = window.web3.utils.toWei(value.toString(), "ether")
    this.props.action(this.props.token.tokenId, wei)
  }

  render() {
    return (
      <div>
        <button type="button" className="btn btn-primary" disabled={this.props.disabled} data-toggle="modal" data-target="#centerModal">{this.props.disabled ? this.state.disabledTitle : this.state.buttonTitle}</button>
        <div className="modal fade" id="centerModal" tabIndex="-1" role="dialog" aria-labelledby="centerModalTitle" aria-hidden="true">
          <form onSubmit={(button) => {
            button.preventDefault()
            this.submitValue(this.state.value)
          }}>
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">{this.state.headerTitle}</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">Ξ</span>
                    </div>
                    <input
                      required
                      type="number"
                      name="amount"
                      step=".01"
                      id="inputTextfield"
                      className="form-control"
                      placeholder="10.00"
                      onChange={(button) => this.setState({ value: button.target.value })}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    value={this.state.value}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default AmountModal
