import React from "react"

const ConnectToMetamask = () => {

  const connectToMetamask = async () => {
    await window.ethereum.enable()
    window.location.reload()
  }

  return (
    <div>
      <button onClick={connectToMetamask} className="btn btn-primary" style={{ letterSpacing: "0.14rem" }}>
        Connect wallet
      </button>
    </div>
  )
}

export default ConnectToMetamask
