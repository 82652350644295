import { useParams, Link } from "react-router-dom"
import React from "react"

const TokenTraits = ({ tokens }) => {
  const { id } = useParams()
  const token = tokens[id - 1]
  const traits = token.metadata.attributes

  const traitPercent = (value, traitType) => {
    const traits = tokens.flatMap(token => token.metadata.attributes)
    const found = traits.filter(attribute => attribute.value === value && attribute.trait_type === traitType).length
    return Math.floor((found / tokens.length) * 100)
  }

  return (
    <div className="mb-5">
      <h3>Traits</h3>
      <div className="row row-cols-3">
        {traits.map((trait, i) => {
          return (
            <div key={i} className="col">
              <Link to={`/traits/${trait.value}-${trait.trait_type}`} className="link-primary"><span>{trait.value} {trait.trait_type}</span></Link>
              <p><b>{traitPercent(trait.value, trait.trait_type)}%</b> have this trait</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TokenTraits
