import ConnectToMetamask from "../ConnectMetamask/ConnectToMetamask"
import React, { useState } from "react"
import Clocks from "./clocks.gif"

const MintToken = ({ contract, accountAddress, userMintAmount, royaltyFee, mintingCost }) => {
    const [isMinting, setIsMinting] = useState(false)

    const mintTokens = () => {
        contract.methods
            .mintSome()
            .send({ from: accountAddress, value: 100000000000000000 })
            .on("transactionHash", () => {
                setIsMinting(true)
            })
            .on("confirmation", () => {
                window.location.reload()
            })
            .on("error", () => {
                setIsMinting(false)
            })
    }

    let price = 1000
    let secondPrice = 3000

    const earning = (price) => {
        return price * (royaltyFee / 100)
    }

    return (
        <div className="row">
            <div className="col-8">
                <div className="mb-5"><h1>Earn royalties for minting CCX</h1></div>
                <div className="mb-5">
                    When you mint Crypto Clocks you will receive <b>{userMintAmount}</b> tokens that you then own and can collect or sell.
                    As the minter of a Crypto Clock token you'll earn up to <b>{royaltyFee}%</b> royalties every time that clock is sold in the future. Minting a Crypto Clock costs <b>{0.03} Ξ</b> (plus gas) per token.
                </div>
                <div className="mb-5">
                    <h5>Royalty fee example</h5>
                    Lets say you mint some Crypto Clocks and you sell one of them to Alice for <b>$100</b>.
                    When Alice sells that same token to Bob for <b>${price}</b>, you — as the initial minter of that token — will receive <b>${earning(price)}</b> from that sale.
                    And Alice will receive the remaining <b>${price - earning(price)}</b>.
                </div>
                <div className="mb-5">
                    <h5>Another example</h5>
                    Charlie sells one of the tokens he bought from someone who bought it from you, for ${secondPrice}. You will then receive ${earning(secondPrice)} from that sale, i.e. {royaltyFee}% of that price.
                    And Charlie will receive the remaining ${secondPrice - earning(secondPrice)}.
                </div>
                <div className="mt-4">
                    {accountAddress !== undefined ?
                        <div className="row">
                            <div className="col-md-auto">
                                <button className="btn btn-primary" disabled={isMinting} onClick={(button) => {
                                    button.preventDefault()
                                    mintTokens()
                                }}>
                                    {isMinting ? "Minting Crypto Clocks…" : "Start minting"}
                                </button>
                            </div>
                            {isMinting ?
                                <div className="col-md-auto">
                                    <div className="col"><div className="spinner-border" /></div>
                                </div>
                                : null
                            }
                        </div>
                        :
                        <>
                            Connect your Metamask wallet to start minting Crypto Clocks.
                            <div className="mt-3 mb-5">
                                <ConnectToMetamask />
                            </div>
                        </>
                    }
                </div>
            </div>
            <div className="col text-center">
                <img src={Clocks} alt="Mint clocks" height="320px" />
            </div>
        </div>
    )
}

export default MintToken