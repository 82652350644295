import ConnectToMetamask from "../ConnectMetamask/ConnectToMetamask"
import { GetTokenURI, TruncatedAddress } from "../www"
import React, { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import DetailHeader from "./DetailHeader"
import AmountModal from "./AmountModal"
import TokenTraits from "./TokenTraits"

const Details = ({ usdPrice, tokens, offers, bids, accountAddress, contract }) => {
  const [loading, setLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isListingForSale, setIsListingForSale] = useState(false)
  const [isBuying, setIsBuying] = useState(false)
  const [isPlacingBid, setIsPlacingBid] = useState(false)
  const [isWithdrawing, setIsWithdrawing] = useState(false)
  const [isAcceptingBid, setIsAcceptingBid] = useState(false)
  const [isChaningPrice, setIsChangingprice] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)

  const { id } = useParams()

  let index = id - 1
  let token = tokens[index]
  let offer = offers.find(offer => offer.tokenId.toNumber() === parseInt(id))
  let bid = bids.find(bid => bid.tokenId.toNumber() === parseInt(id))

  useEffect(() => {
    setLoading(loading)

    if (tokens.length > 0 && contract !== undefined) {
      setLoading(false)
    }
  }, [tokens, offers, offer, bids, bid])

  const buyToken = (tokenId, price) => {
    contract.methods
      .buyToken(tokenId)
      .send({ from: accountAddress, value: price })
      .on("transactionHash", () => {
        setIsBuying(true)
        setIsLoading(true)
      })
      .on("confirmation", () => {
        window.location.reload()
      })
      .on("error", () => {
        setIsBuying(false)
        setIsLoading(false)
      })
  }

  const listForSale = (tokenId, newPrice) => {
    contract.methods
      .offerToken(tokenId, newPrice)
      .send({ from: accountAddress })
      .on("transactionHash", () => {
        setIsListingForSale(true)
        setIsLoading(true)
      })
      .on("confirmation", () => {
        window.location.reload()
      })
      .on("error", () => {
        setIsListingForSale(false)
        setIsLoading(false)
      })
  }

  const updateTokenPrice = (tokenId, newPrice) => {
    contract.methods
      .updateTokenPrice(tokenId, newPrice)
      .send({ from: accountAddress })
      .on("transactionHash", () => {
        setIsChangingprice(true)
        setIsLoading(true)
      })
      .on("confirmation", () => {
        window.location.reload()
      })
      .on("error", () => {
        setIsChangingprice(false)
        setIsLoading(false)
      })
  }

  const removeFromSale = (button, tokenId) => {
    contract.methods
      .removeTokenFromSale(tokenId)
      .send({ from: accountAddress })
      .on("transactionHash", () => {
        setIsRemoving(true)
        setIsLoading(true)
      })
      .on("confirmation", () => {
        window.location.reload()
      })
      .on("error", () => {
        setIsRemoving(false)
        setIsLoading(false)
      })
  }

  const placeBid = (tokenId, price) => {
    contract.methods
      .bid(tokenId)
      .send({ from: accountAddress, value: price })
      .on("transactionHash", () => {
        setIsPlacingBid(true)
        setIsLoading(true)
      })
      .on("confirmation", () => {
        window.location.reload()
      })
      .on("error", () => {
        setIsPlacingBid(false)
        setIsLoading(false)
      })
  }

  const withdrawBid = (tokenId) => {
    contract.methods
      .withdrawBid(tokenId)
      .send({ from: accountAddress })
      .on("transactionHash", () => {
        setIsWithdrawing(true)
        setIsLoading(true)
      })
      .on("confirmation", () => {
        window.location.reload()
      })
      .on("error", () => {
        setIsWithdrawing(false)
        setIsLoading(false)
      })
  }

  const acceptBid = (tokenId) => {
    contract.methods
      .acceptHighestBid(tokenId)
      .send({ from: accountAddress })
      .on("transactionHash", () => {
        setIsAcceptingBid(true)
        setIsLoading(true)
      })
      .on("confirmation", () => {
        window.location.reload()
      })
      .on("error", () => {
        setIsAcceptingBid(false)
        setIsLoading(false)
      })
  }

  const isOwner = () => {
    return (token.owner === accountAddress)
  }

  const isBidder = () => {
    return (bid.bidder === accountAddress)
  }

  const hasBid = () => {
    return bid !== undefined
  }

  const isForSale = () => {
    return offer !== undefined
  }

  const etherFromWei = (amount) => {
    return window.web3.utils.fromWei(amount.toString(), "ether") + " Ξ"
  }

  const bidder = () => {
    return isBidder() ? "you" : <Link to={`/address/${bid.bidder}`} className="link-primary">{TruncatedAddress(bid.bidder)}</Link>
  }

  const calculateUSDPrice = (wei) => {
    let price = window.web3.utils.fromWei(wei.toString(), "ether")
    let round = 10
    let converted = Math.round(usdPrice * price * round) / round
    return converted.toLocaleString()
  }

  return (
    <div>
      {loading ?
        null
        :
        <>
          <div className="text-center" style={{ backgroundColor: `#${token.metadata.background_color}` }}>
            <img src={GetTokenURI(token.metadata.image)} alt={`Crypto Clock ${token.tokenId}`} height="320px" />
          </div>
          <div className="m-5">
            <DetailHeader props={{ token, isOwner }} />
            <TokenTraits tokens={tokens} />

            <h3>Status</h3>
            {token.soldFor > 0 ?
              <div className="row">
                <p>Previously sold for <b>{etherFromWei(token.soldFor)} (${calculateUSDPrice(token.soldFor)})</b></p>
              </div>
              :
              null
            }
            <div className="row">
              {isForSale() ?
                <p>Listed for sale at <b>{etherFromWei(offer.price)} (${calculateUSDPrice(offer.price)})</b></p>
                :
                <p>Not listed for sale</p>
              }
            </div>
            <div className="row mb-4">
              {hasBid() ?
                <p>The highest bid is <b>{etherFromWei(bid.value)} (${calculateUSDPrice(bid.value)})</b> by {bidder()}</p>
                :
                <p>Currently no bids on this clock</p>
              }
            </div>
            {accountAddress !== undefined ?
              <div className="row">
                {isOwner() ?
                  <>
                    {hasBid() ?
                      <>
                        <div id="acceptBid" className="col-md-auto">
                          <button className="btn btn-primary" type="button" disabled={isAcceptingBid} onClick={(button) => {
                            button.preventDefault()
                            acceptBid(token.tokenId)
                          }}>
                            {isAcceptingBid ? "Accepting bid…" : "Accept bid"}
                          </button>
                        </div>
                      </>
                      :
                      null
                    }
                    {isForSale() ?
                      <>
                        <div id="changePrice" className="col-md-auto">
                          <AmountModal
                            token={token}
                            action={updateTokenPrice}
                            disabled={isChaningPrice}
                            disabledTitle={"Changing price…"}
                            headerTitle={"Change clock price"}
                            placeholder={"New price"}
                            buttonTitle={"Change price"}
                          />
                        </div>
                        <div id="removeSale" className="col-md-auto">
                          <button className="btn btn-outline-danger" type="button" disabled={isRemoving} onClick={(button) => {
                            button.preventDefault()
                            removeFromSale(button, token.tokenId)
                          }}>
                            {isRemoving ? "Removing…" : "Remove from sale"}
                          </button>
                        </div>
                      </>
                      :
                      <>
                        <div id="sellClock" className="col-md-auto">
                          <AmountModal
                            token={token}
                            action={listForSale}
                            disabled={isListingForSale}
                            disabledTitle={"Listing…"}
                            headerTitle={"Set clock price"}
                            buttonTitle={"List for sale"}
                          />
                        </div>
                      </>
                    }
                  </>
                  :
                  <>
                    {isForSale() ?
                      <>
                        <div className="col-md-auto">
                          <button id="buyClock" className="btn btn-primary" type="button" disabled={isBuying} onClick={(button) => {
                            button.preventDefault()
                            buyToken(token.tokenId, offer.price)
                          }}>
                            {isBuying ? "Buying…" : `Buy for ${etherFromWei(offer.price)}`}
                          </button>
                        </div>
                      </>
                      :
                      null
                    }
                    <div id="placeNewBid" className="col-md-auto">
                      <>
                        <AmountModal
                          token={token}
                          action={placeBid}
                          disabled={isPlacingBid}
                          disabledTitle={"Placing bid…"}
                          headerTitle={"Place a new bid"}
                          buttonTitle={"Place new bid"}
                        />
                      </>
                    </div>
                    {(hasBid() && isBidder()) ?
                      <>
                        <div className="col-md-auto">
                          <button id="withdrawBid" className="btn btn-outline-danger" type="button" disabled={isWithdrawing} onClick={(button) => {
                            button.preventDefault()
                            withdrawBid(token.tokenId)
                          }}>
                            {isWithdrawing ? "Withdrawing bid…" : "Withdraw bid"}
                          </button>
                        </div>
                      </>
                      :
                      null
                    }
                  </>
                }
                {isLoading ? <div className="col"><div className="spinner-border" /></div> : null}
              </div>
              :
              <>
                <p>Connect your Metamask wallet if you want to buy, sell and interact with the marketplace.</p>
                <ConnectToMetamask />
              </>
            }
          </div>
        </>
      }
    </div>
  )
}

export default Details