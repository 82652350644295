import { DiscordURL } from "../Home/Home"
import Discord from "./discord-logo.png"
import { Link } from "react-router-dom"
import React from "react"

const Footer = _ => {
  return (
    <footer className="mt-auto" style={{ fontSize: "14px" }}>
      <ul className="nav justify-content-end">
        <li className="nav-item"><Link to="/mint" className="nav-link" style={{ color: "gray" }}>Mint CCX</Link></li>
        <li className="nav-item"><Link to="/clocks" className="nav-link" style={{ color: "gray" }}>Marketplace</Link></li>
        <li className="nav-item">
          <a href={DiscordURL} className="nav-link" style={{ paddingRight: "0" }} target="_blank" rel="noopener noreferrer">
            <img src={Discord} alt="Discord Logo" height="26px" />
          </a>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
