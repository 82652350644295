
import React, { useState, useEffect } from "react"
import TokenDetail from "./TokenDetail"
import Traits from "../Traits/Traits"

const Marketplace = ({ usdPrice, tokens, offers, bids }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (tokens.length > 0 && tokens[0] !== undefined) {
      setLoading(false)
    }
  }, [tokens, offers, bids])

  return (
    <div>
      {loading ?
        null
        :
        <>
          <h1>Clocks for sale</h1>
          <div className="d-flex flex-wrap mb-5 mt-5">
            {offers.map((offer) => {
              let token = tokens[offer.tokenId - 1]
              return (
                <div key={token.tokenId}>
                  <TokenDetail token={token} wei={offer.price} usdPrice={usdPrice} />
                </div>
              )
            })}
          </div>
          <h1>Current bids</h1>
          <div className="d-flex flex-wrap mb-5 mt-5">
            {bids.map((bid) => {
              let token = tokens[bid.tokenId - 1]
              return (
                <div key={token.tokenId}>
                  <TokenDetail token={token} wei={bid.value} usdPrice={usdPrice} />
                </div>
              )
            })}
          </div>
          <h1>Top sales</h1>
          <div className="d-flex flex-wrap mb-5 mt-5">
            {tokens.filter(token => token.soldFor > 0).map((token) => {
              return (
                <div key={token.tokenId}>
                  <TokenDetail token={token} wei={token.soldFor} usdPrice={usdPrice} />
                </div>
              )
            })}
          </div>
          <Traits tokens={tokens} />
        </>
      }
    </div>
  )
}

export default Marketplace
