import TokenDetail from "../Marketplace/TokenDetail"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const Trait = ({ tokens }) => {
    const [filteredTokens, setFilteredTokens] = useState([])
    const { trait } = useParams()

    let traits = trait.split("-")
    let traitType = traits[1]
    let value = traits[0]

    useEffect(() => {
        let ts = tokens.filter((token) => {
            let traits = token.metadata.attributes.find(attr => attr.trait_type === traitType)
            return traits.value === value
        })
        setFilteredTokens(ts)
    }, [tokens])

    return (
        <>
            <h3>{filteredTokens.length} clocks with {value} {traitType}</h3>
            <div className="d-flex flex-wrap">
                {filteredTokens.map((token) => {
                    return (<TokenDetail token={token} wei={0} size={100}/>)
                })}
            </div>
        </>
    )
}

export default Trait
